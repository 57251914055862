import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useGetCategoriesMutation } from 'redux/services/categories.services';
import CategoriesOption from '../AutocompleteComponents/CategoriesOption';
import CKEditorEditor from './CKEditorEditor';

const RichTextEditor = ({
  id,
  data,
  onChange,
  parseHTML = true,
}: {
  id?: string;
  data?: string;
  onChange?: (value: string) => void;
  parseHTML?: boolean;
}) => {
  const [showCategoryBlock, setShowCategoryBlock] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <Box>
      <Grid container gap={2}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCategoryBlock}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    setShowCategoryBlock(true);
                    divRef.current?.classList.add('category-block');
                  } else {
                    setShowCategoryBlock(false);
                    divRef.current?.classList.remove('category-block');
                  }
                }}
              />
            }
            label="Show Block"
          />
        </Grid>
      </Grid>

      <div ref={divRef} className="category-block">
        <CKEditorEditor value={data} onChange={onChange} />
      </div>
    </Box>
  );
};

export default RichTextEditor;
