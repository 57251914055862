import { CKEditor } from '@ckeditor/ckeditor5-react';

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import type { Editor, EditorConfig } from '@ckeditor/ckeditor5-core';

import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Heading } from '@ckeditor/ckeditor5-heading';
import {
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { useEffect, useRef, useState } from 'react';
import { Category } from './Plugins/Category/src';
import { Button } from '@mui/material';
import { useGetCategoriesMutation } from 'redux/services/categories.services';

const editorConfiguration: EditorConfig = {
  plugins: [
    Alignment,
    Autoformat,
    BlockQuote,
    Bold,
    CloudServices,
    Essentials,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    Table,
    TableToolbar,
    TextTransformation,
    GeneralHtmlSupport,
    SourceEditing,
    Category,
  ],
  toolbar: {
    items: [
      'sourceEditing',
      'alignment',
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      '|',
      'imageUpload',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo',
      'category',
    ],
    shouldNotGroupWhenFull: true,
  },
};

type CATEGORY = {
  _id: string;
  childs: Array<CATEGORY>;
  vi: {
    name: string;
  };
};

const CKEditorEditor = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (data: string) => void;
}) => {
  const editorRef = useRef<Editor>();
  // const [
  //   getCategories,
  //   { isLoading: isLoadingCategories, data: listCategories },
  // ] = useGetAllCategoriesMutation();
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [listCategories, setListCategories] = useState<
    Array<{ _id: string; vi: { name: string } }>
  >([]);

  useEffect(() => {
    fetch(
      'https://services-k8s.askany-technology.com/categories?isShowAll=true&isAll=true',
    )
      .then(r => r.json())
      .then(result => {
        const childs: CATEGORY[] = [];
        const list: CATEGORY[] = [];
        childs.push(...(result.data as CATEGORY[]));
        while (childs.length > 0) {
          const cate = childs.shift();
          if (cate) {
            list.push(cate);
            if (cate.childs) {
              childs.push(...cate.childs);
            }
          }
        }
        setListCategories(list);
      })
      .finally(() => setIsLoadingCategories(false));
  }, []);

  if (isLoadingCategories || !listCategories) {
    return <></>;
  }
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        config={{
          ...editorConfiguration,
          htmlSupport: {
            allow: [
              {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          categories: {
            options:
              listCategories?.map(cate => ({
                id: cate._id,
                name: cate.vi?.name,
              })) || [],
          },
        }}
        data={value}
        onChange={(e, editor) => {
          onChange?.(editor.getData());
        }}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          editorRef.current = editor;
          // console.log('Editor is ready to use!', editor);
        }}
      />
    </>
  );
};

export default CKEditorEditor;
